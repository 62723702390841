function waitForGA4Event(event, maxWaitTime, intervalTime, callback) {
  var elapsedTime = 0;

  var checkDataLayer = setInterval(function() {
    elapsedTime += intervalTime;
    var eventFound = false;

    if (window.dataLayer) {
      for (var i = 0; i < window.dataLayer.length; i++) {
        if (window.dataLayer[i].event == event) {
          eventFound = true;
          break;
        }
      }
    }

    if (eventFound || elapsedTime >= maxWaitTime) {
      clearInterval(checkDataLayer);
      if (callback) {
        callback();
      }
    }
  }, intervalTime);
}

function gtmPush(event, ecommerce) {
  waitForGA4Event('OneTrustGroupsUpdated', 5000, 100, function() {
    if (!window.dataLayer) {
      window.dataLayer = [];
    } else {
      window.dataLayer.push({ecommerce: null});
    }

    var newEventEntry = {};

    if (typeof event === 'string') {
      newEventEntry.event = event;
    } else if (typeof event === 'object') {
      for (var key in event) {
        if (event.hasOwnProperty(key)) {
          newEventEntry[key] = event[key];
        }
      }
    }

    if (typeof ecommerce === 'object') {
      newEventEntry.ecommerce = {};

      for (var ecommerceKey in ecommerce) {
        if (ecommerce.hasOwnProperty(ecommerceKey)) {
          newEventEntry.ecommerce[ecommerceKey] = ecommerce[ecommerceKey];
        }
      }
    }

    if (Object.keys(newEventEntry).length > 0) {
      window.dataLayer.push(newEventEntry);
    }
  });
}
